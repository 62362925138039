/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
//@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";


// scss-docs-start color-variables
$blue:    #004e7d !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #FC4243 !default;
$orange:  #fd7e14 !default;
$yellow:  #FFC000 !default;
$green:   #00A878 !default;
$teal:    #004E7D !default;
$cyan:    #0dcaf0 !default;
// scss-docs-end color-variables

@import "bootstrap/scss/bootstrap";



//  As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$primary-palette: (
    50 : #e0f5ef,
    100 : #b3e5d7,
    200 : #80d4bc,
    300 : #4dc2a1,
    400 : #26b58c,
    500 : #00a878,
    600 : #00a070,
    700 : #009765,
    800 : #008d5b,
    900 : #007d48,
    A100 : #aaffd5,
    A200 : #77ffbc,
    A400 : #44ffa3,
    A700 : #2aff97,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$accent-palette: (
    50 : #e0eaef,
    100 : #b3cad8,
    200 : #80a7be,
    300 : #4d83a4,
    400 : #266991,
    500 : #004e7d,
    600 : #004775,
    700 : #003d6a,
    800 : #003560,
    900 : #00254d,
    A100 : #80b0ff,
    A200 : #4d90ff,
    A400 : #1a71ff,
    A700 : #0161ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$warn-palette: (
    50 : #ffe8e8,
    100 : #fec6c7,
    200 : #fea1a1,
    300 : #fd7b7b,
    400 : #fc5e5f,
    500 : #fc4243,
    600 : #fc3c3d,
    700 : #fb3334,
    800 : #fb2b2c,
    900 : #fa1d1e,
    A100 : #ffffff,
    A200 : #fffcfc,
    A400 : #ffc9c9,
    A700 : #ffafaf,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$my-primary: mat.define-palette($primary-palette);
$my-accent: mat.define-palette($accent-palette);
$my-warn: mat.define-palette($warn-palette);

$my-theme: mat.define-light-theme((
    color: (
        primary: $my-primary,
        accent: $my-accent,
        warn: $my-warn
    )
));

@include mat.all-legacy-component-themes($my-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

*{
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Roboto', sans-serif;
}

.fw-semi-bold {
    font-weight: 500;
}
.mat-form-field {
    width: 100%;
}

.full-width{
    margin: 0 auto;
    max-width: 1366px;
    width: 100%;
    position: relative;
}

.top-bar{
    background-color: #004E7D;
    color: #fff;
}

.top-bar ul{
    margin: 3px 0;
    text-align: right;
}

.top-bar ul li {
    display: inline-block;
    margin: 0 0 0 12px;
}

.top-bar ul li i{
    font-size: 24px;
    line-height: 34px;
}

.top-bar ul li a{
    color: #fff;
}

.navbar-expand-lg .navbar-collapse {
    display: block !important;
}

.menu-container {
    padding: 10px 0;
    border-bottom: 1px solid #004E7D;
}

.navbar.menu-bar {
    padding: 5px 0 10px;
}

.navbar.menu-bar .logo {
    background-image: url('../src/assets/true360logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: -9999px;
    width: 200px;
    margin: 5px 0 0;
}

.navbar.menu-bar ul {
    text-align: right;
    margin: 5px 0 0;
    display: block;
}

.navbar.menu-bar ul li {
    display: inline-block;
    font-weight: 700;
    padding: 0 15px;
    font-size: 16px;
    line-height: 24px;
}

.navbar.menu-bar ul li:last-child {
    padding: 0 0 0 15px;
}

.navbar.menu-bar ul li a {
    color: #000;
    text-decoration: none;
}

.navbar-light .navbar-nav .nav-link{
    color: #000;
}

.navbar.menu-bar ul li span {
    cursor: pointer;
}

.hero-section {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e679c",endColorstr="#ffffff",GradientType=1);
    background-image: url('../src/assets/hero_backdrop.png');
    min-height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 55px 0 0;

    background: rgb(14,103,156);
    background-image: url("../src/assets/hero_backdrop.png"), linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(92,173,218,1) 60%, rgba(14,103,156,1) 100%); /* W3C */
    background-position: 50% 75%;

    text-align: center;
}

.hero-section h1 {
    color: #fff;
    font-size: 80px;
    font-weight: 900;
    text-shadow: 0 3px 10px rgba(0, 0, 0, 0.18);
    text-align: center;
    line-height: 80px;
    margin: 20px auto 60px;
}

.hero-section p{
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 96px;
}

.hero-section .car {
    background-image: url('../src/assets/car1.png');
    height: 267px;
    width: 618px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 20;
    position: relative;
    margin: 50px auto;
}

@media screen and (max-width: 600px) {
.hero-section .car
      {
        width: 381px;
      }
.imgsuv img
      {
        width: 381px;
      }
}

.FAQ-ACVAnywhere-dialog
{
    user-select: none;
}

@media screen and (max-width: 600px) {
    .FAQ-ACVAnywhere-dialog .main
          {
            width: 400px;
          }
}

.hero-section .car-group {
    background-image: url('../src/assets/car-group.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 213px;
    width: 70%;
    position: relative;
    margin: 50px auto;
    z-index: 20;
}

.hero-section .triangle {
    width: 0;
    height: 0;
    border-left: 683px solid transparent;
    border-right: 683px solid transparent;
    border-bottom: 100px solid white;
    position: absolute;
    bottom: 0;
    right: 0;
}

.info-section{
    margin: 10px auto 0;
    text-align: center;
    width: 80%;
}

.info-section h3 {
    color: #004E7D;
    font-size: 60px;
    font-weight: 900;
    line-height: 60px;
}

.info-section .subheading{
    color: #004E7D;
    font-size: 30px;
    font-weight: 900;
    margin: 45px 0 30px;
    text-transform: uppercase;
}

.info-section p{
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
}

.info-section ul{
    margin: 80px 0 0;
}

.info-section ul li {
    display: inline-block;
    width: 30%;
    vertical-align: top;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    max-width: 360px;
    margin: 0 10px 0 10px;
    height: 289px;
}

.info-section ul li.box1 {
    padding: 25px 30px;
}

.info-section ul li.box1 p {
    margin: 25px 0 0;
}

.info-section ul li.box2 {
    padding: 25px 30px;
}

.info-section ul li.box2 p {
    margin: 28px 0 0;
}

.info-section ul li.box3 {
    padding: 25px 10px;
}

.info-section ul li h4{
    color: #004E7D;
    font-weight: 900;
    font-size: 27px;
    line-height: 30px;
}

.home-info-section h3{
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
    color: #004E7D;
    text-transform: uppercase;
}

.home-info-section h3.extra-padding{
    padding: 20px 0 0;
}

.home-info-section .info-block {
    width: 64%;
    margin: 0 auto;
    text-align: center;
}

.home-info-section .info-block p{
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

@media screen and (max-width: 600px) {
    .home-info-section .info-block p
          {
            font-size: 21px;
          }
}

.home-info-section ul {
    margin: 80px 0;
    text-align: center;
    padding: 0;
}

.home-info-section ul li {
    display: inline-block;
    width: 30%;
    vertical-align: top;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    max-width: 360px;
    margin: 0 10px 0 10px;
    min-height: 430px;
}

.home-info-section ul li.box1 {
    padding: 30px;
}

.home-info-section ul li.box2 {
    padding: 40px 30px 20px;
}

.home-info-section ul li.box3 {
    padding: 18px 10px;
}

.home-info-section ul li p {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #001F32;
}

.inspection-ways:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    transform-origin: left top;
    transform: skewY(-4deg);
}

.inspection-ways {
    background-color: #F8F8F8;
    margin: 115px 0 0;
    padding: 20px 0 35px;
    position: relative;
    overflow: visible;
    z-index: 1;
}

h3.section-heading{
    color: #004E7D;
    font-weight: 900;
    font-size: 60px;
    line-height: 65px;
}

.inspection-ways .white-box {
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-position: 20px 25px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 30px 25px 30px 75px;
    width: 100%;
    max-width: 544px;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #4A5B61;
}

.inspection-ways .white-box:nth-child(2) {
    margin: 20px 0 0;
}

.inspection-ways .white-box h4{
    color: #004E7D;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
    text-transform: uppercase;
}

.inspection-ways .white-box.call{
    background-image: url("../src/assets/icon_phone.png");
}

.inspection-ways .white-box.schedule{
    background-image: url("../src/assets/icon_laptop.png");
}

.questions p{
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.questions p.emphasis{
    color: #004E7D;
    font-weight: 900;
    font-size: 30px;
    text-transform: uppercase;
    margin: 20px 0;
}

.questions .question1 .col-md-6{
    background-image: url('../src/assets/us_map.png');
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 300px;
    min-width: auto;
}

.questions .question1 .section-heading{
    margin: 0 0 20px;
}

.questions .question1 p{
    margin: 0 0 30px;
}

.questions .question2 {
    background-image: url('../src/assets/pickup-truck.png');
    background-repeat: no-repeat;
    background-position: 100% center;
    padding: 85px 0 50px;
    margin: 50px 0 0;
    background-size: contain;
}

.about-inspection:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    transform-origin: left top;
    transform: skewY(-4deg);
}

.about-inspection {
    background-color: #F8F8F8;
    margin: 115px 0 0;
    padding: 40px 0 35px;
    position: relative;
    overflow: visible;
    z-index: 1;
}

.about-inspection h3 {
    font-weight: 900;
    font-size: 60px;
    line-height: 65px;

    text-align: center;

    color: #004E7D;
}

.about-inspection h4 {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;

    text-align: center;
    text-indent: 1px;
    text-transform: uppercase;

    color: #004E7D;
}

.about-inspection ul {
    list-style: none;
    margin: 0 0 50px;
}

.about-inspection ul li {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-indent: 1px;
    color: #001F32;
    position: relative;
    padding: 0 0 0 35px;
    margin: 30px 0 0;
}

.about-inspection ul li span {
    position: absolute;
    top: 0;
    left: 0;
}

.about-inspection .what-we-inspect{
    margin: 60px 0 0;
}

.about-inspection .inspection {
    background-repeat: no-repeat;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin: 10px 0;
    padding: 10px 0 10px 60px;
}

.about-inspection .exterior{
    background-image: url("../src/assets/HowWeAssess_paint.svg");
    margin: 20px 0 0;
}

.about-inspection .interior{
    background-image: url("../src/assets/HowWeAssess_tachometer.svg");
    margin: 20px 0 0;
}

.about-inspection .structure{
    background-image: url("../src/assets/HowWeAssess_clipboard.svg");
    margin: 35px 0 0;
}

.about-inspection .engine{
    background-image: url("../src/assets/HowWeAssess_tools.svg");
    margin: 35px 0 0;
}

.text-right{
    text-align: right;
}

.green-btn{
    background-color: #00A878;
    border: 0;
    border-radius: 5px;
    color: #ffffff;
    padding: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    vertical-align: middle;
}

.green-btn:disabled{
    opacity: 0.4;
}

a.green-btn {
    text-decoration: none;
    display: inline-block;
}

a.green-btn:hover{
    color: #fff;
}

.green-btn .mat-progress-spinner{
    display: inline-block;
    width: 30px;
}

.green-btn span.text{
    display: inline-block;
    height: 40px;
    vertical-align: middle;
}

.green-btn .mat-progress-spinner circle{
    stroke: #fff;
}

.border-0{
    border: 0;
}

.help-btn{
    border: 0;
    position: relative;
}

.help-btn span {
    background-color: #fff;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    top: -27px;
    right: 2px;
}

.help-btn span:hover {
    box-shadow: 0 0 5px #000;
}

.forgot-link{
    color: #004E7D;
}

.footer-banner {
    background-color: #F26522;
    min-height: 130px;
    padding: 30px 0 0;
}

.footer-banner .white-logo {
    background-image: url('../src/assets/True360-White-Logo.svg');
    background-repeat: no-repeat;
    text-indent: -9999px;
    width: 350px;
    background-size: cover;
    height: 65px;
}

.footer {
    background-color: #004E7D;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    padding: 10px 0 0;
}

.footer a{
    color: #fff;
    text-decoration: none;
}

@media only screen and (max-width: 1366px) {
    .full-width{
        padding: 0 10px;
        width: 100%;
    }

    .hero-section .triangle {
        width: 0;
        height: 0;
        border-left: 50vw solid transparent;
        border-right: 50vw solid transparent;
        border-bottom: 100px solid white;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media only screen and (max-width: 1160px) {
    .home-info-section img{
        display: none;
    }

    .hero-section .car-group {
        width: 90%;
        height: 175px;
    }

    .info-section ul li {
        display: block;
        width: 100%;
        max-width: none;
        height: auto;
        margin: 20px 0 0;
    }
}

@media only screen and (max-width: 990px) {
    .navbar-expand-lg .navbar-collapse {
        display: none !important;
    }

    .navbar-expand-lg .navbar-collapse.show {
        display: block !important;
    }

    .navbar.menu-bar ul li {
        display: block;
        width: 100%;
        text-align: left;
    }
}

@media only screen and (max-width: 860px) {
    .home-info-section ul li {
        display: block;
        width: 100%;
        max-width: none;
        min-height: auto;
        margin: 0 10px 25px 10px;
    }

    .home-info-section ul li.box1, .home-info-section ul li.box2, .home-info-section ul li.box3 {
        padding: 30px 0;
    }

    .hero-section .car-group {
        width: 100%;
        height: 165px;
    }

    .inspection-ways .white-box{
        background-position: center 25px;
        text-align: center;
        padding: 80px 25px 30px 25px;
        max-width: none;
    }

    .questions .question1 {
        padding: 330px 0 0;
        background-position: top center;
        margin: 0 0 10px;
        background-size: contain;
    }

    .questions .question2 {
        background-position: top center;
        padding: 330px 0 0;
        margin: 0 0 0;
    }

    .about-inspection {
        margin: 50px 0 0;
    }

    .about-inspection .column-1, .about-inspection .column-2 {
        margin: 0;
    }

    .about-inspection .column-2 {
        margin: 0 0 50px;
    }
}
